/* bootstrap overwrite */
.pages-watch-list-component .teaser .teaser-body .teaser-decoration .teaser-icon .icon-mask,
.pages-search-list-component .teaser .teaser-body .teaser-decoration .teaser-icon .icon-mask {
  background: #657785;
}
.pages-watch-list-component .teaser:hover .teaser-icon .icon-mask,
.pages-search-list-component .teaser:hover .teaser-icon .icon-mask {
  background: var(--accent-gradient);
}
.pages-watch-list-component .teaser:hover .teaser-tagline,
.pages-search-list-component .teaser:hover .teaser-tagline {
  background: transparent;
  background-clip: unset;
  color: #546983;
}