@import 'webresources-sources/scss/imports';

.pages-watch-list-component,
.pages-search-list-component {
  .teaser {
    .teaser-body {
      .teaser-decoration {
        .teaser-icon {
          .icon-mask {
            background: $color-gray-600;
          }
        }
      }
    }

    &:hover {
      .teaser-icon {
        .icon-mask {
          background: var(--accent-gradient);
        }
      }

      .teaser-tagline {
        background: transparent;
        background-clip: unset;
        color: $color-darkgray-700;
      }
    }
  }
}
